import { useEffect } from 'react';
export function useClickOutside(ref, callback, ignoreRef) {
    useEffect(() => {
        const eventListenerCallback = (event) => {
            var _a;
            if (ref.current == null || ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)))
                return;
            if ((ignoreRef === null || ignoreRef === void 0 ? void 0 : ignoreRef.current) != null && (ignoreRef === null || ignoreRef === void 0 ? void 0 : ignoreRef.current.contains(event.target)))
                return;
            callback(event);
        };
        window.addEventListener('mousedown', eventListenerCallback);
        window.addEventListener('touchstart', eventListenerCallback);
        return () => {
            window.removeEventListener('mousedown', eventListenerCallback);
            window.removeEventListener('touchstart', eventListenerCallback);
        };
    }, [callback]);
}
