export var ButtonKinds;
(function (ButtonKinds) {
    ButtonKinds["primary"] = "primary";
    ButtonKinds["secondary"] = "secondary";
    ButtonKinds["text"] = "text";
    ButtonKinds["pink"] = "pink";
})(ButtonKinds || (ButtonKinds = {}));
export var Colors;
(function (Colors) {
    Colors["teal"] = "teal";
    Colors["pink"] = "pink";
    Colors["red"] = "red";
    Colors["grey"] = "grey";
})(Colors || (Colors = {}));
export var ButtonSizes;
(function (ButtonSizes) {
    ButtonSizes["small"] = "small";
    ButtonSizes["medium"] = "medium";
    ButtonSizes["fluid"] = "fluid";
    ButtonSizes["smallFluid"] = "smallFluid";
})(ButtonSizes || (ButtonSizes = {}));
