import { useMedia } from './useMedia';
export var BREAKPOINT;
(function (BREAKPOINT) {
    BREAKPOINT[BREAKPOINT["xs"] = 576] = "xs";
    BREAKPOINT[BREAKPOINT["sm"] = 768] = "sm";
    BREAKPOINT[BREAKPOINT["md"] = 992] = "md";
    BREAKPOINT[BREAKPOINT["lg"] = 1200] = "lg";
})(BREAKPOINT || (BREAKPOINT = {}));
var ORIENTATION;
(function (ORIENTATION) {
    ORIENTATION["landscape"] = "landscape";
    ORIENTATION["portrait"] = "portrait";
})(ORIENTATION || (ORIENTATION = {}));
export function useResponsiveness() {
    const isLandscape = useMedia('(orientation: landscape)');
    const isBelowXs = useMedia(`(max-width: ${BREAKPOINT.xs - 1}px)`);
    const isBelowSm = useMedia(`(max-width: ${BREAKPOINT.sm - 1}px)`);
    const isBelowMd = useMedia(`(max-width: ${BREAKPOINT.md - 1}px)`);
    const isBelowLg = useMedia(`(max-width: ${BREAKPOINT.lg - 1}px)`);
    const isAboveXs = useMedia(`(min-width: ${BREAKPOINT.xs}px)`);
    const isAboveSm = useMedia(`(min-width: ${BREAKPOINT.sm}px)`);
    const isAboveMd = useMedia(`(min-width: ${BREAKPOINT.md}px)`);
    const isAboveLg = useMedia(`(min-width: ${BREAKPOINT.lg}px)`);
    return {
        orientation: isLandscape ? ORIENTATION.landscape : ORIENTATION.portrait,
        below: {
            xs: isBelowXs,
            sm: isBelowSm,
            md: isBelowMd,
            lg: isBelowLg
        },
        above: {
            xs: isAboveXs,
            sm: isAboveSm,
            md: isAboveMd,
            lg: isAboveLg
        }
    };
}
