export var MaritalStatus;
(function (MaritalStatus) {
    MaritalStatus["MARRIED"] = "married";
    MaritalStatus["PARTNERSHIP"] = "partnershipRegistered";
    MaritalStatus["SINGLE"] = "single";
    MaritalStatus["PARTNERS_NOT_MARRIED"] = "partnersNotMarried";
})(MaritalStatus || (MaritalStatus = {}));
export var Gender;
(function (Gender) {
    Gender["MALE"] = "male";
    Gender["FEMALE"] = "female";
})(Gender || (Gender = {}));
export var UserFields;
(function (UserFields) {
    UserFields["PASSWORD"] = "password";
    UserFields["PASSWORD_REPEAT"] = "passwordRepeat";
    UserFields["FIRST_NAME"] = "firstName";
    UserFields["LAST_NAME"] = "lastName";
    UserFields["AGE"] = "age";
    UserFields["EMAIL"] = "email";
    UserFields["PHONE"] = "phone";
    UserFields["PARTNER_PRESENT"] = "partner_present";
    UserFields["RESIDENCE"] = "livingTogether";
    UserFields["MARITAL"] = "maritalStatus";
    UserFields["ASSETS"] = "assets";
    UserFields["CHILDREN"] = "hasChildren";
    UserFields["MARRIAGE"] = "marriagePlanned";
    UserFields["REAL_ESTATES"] = "realEstate";
    UserFields["TERMS"] = "acceptTermsAndConditions";
    UserFields["MARKETING"] = "marketing";
    UserFields["FULL_NAME"] = "fullname";
    UserFields["CITY"] = "city";
    UserFields["POSTAL"] = "postal";
    UserFields["ADDRESS"] = "address";
    UserFields["GENDER"] = "gender";
    UserFields["MARITAL_STATUS"] = "maritalStatus";
    UserFields["TECHNICAL_PROFILE"] = "technical_profile";
})(UserFields || (UserFields = {}));
export var UserTechnicalProfile;
(function (UserTechnicalProfile) {
    UserTechnicalProfile["DIY"] = "diy";
    UserTechnicalProfile["REVIEW"] = "review";
    UserTechnicalProfile["ADVICE"] = "advice";
})(UserTechnicalProfile || (UserTechnicalProfile = {}));
