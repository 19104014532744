import cookieJs from 'js-cookie';
export class Cookie {
    static get(name) {
        return cookieJs.get(name);
    }
    static set(name, value, options) {
        return cookieJs.set(name, value, options);
    }
    static remove(name, options) {
        cookieJs.remove(name, options);
    }
}
