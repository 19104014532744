export function isValidPhoneNumber(string) {
    if (string == null) {
        return false;
    }
    return /^(?:\+45)?[0-9]{8}$/.test(string === null || string === void 0 ? void 0 : string.replace(/ /g, ''));
}
export function validateDanishPhoneNumber(string) {
    if (string == null || string.length === 0 || !/^(\+?\d{1,3}|\d{1,4})?[0-9]{8}$/.test(string.replace(/ /g, ''))) {
        return { isValid: false, message: 'invalid_number' };
    }
    const result = /^(\+?\d{1,3}|\d{1,4})?[0-9]{8}$/.exec(string.replace(/ /g, ''));
    if (result == null) {
        return { isValid: false, message: 'invalid_number' };
    }
    if (result[1] != null && result[1] !== '+45') {
        return { isValid: false, message: 'non_danish_number' };
    }
    return { isValid: true };
}
export function isValidEmail(string) {
    if (string == null) {
        return false;
    }
    const regex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)'
        + '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    return regex.test(string);
}
export function isValidName(string) {
    if (string == null) {
        return false;
    }
    return /[a-zæøåA-ZÆØÅ]{2,}/.test(string);
}
export function isValidPassword(string) {
    if (string == null || string.length < 6) {
        return false;
    }
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp(/[^\w\d]*(([0-9]+.*[A-Za-zÆØÅæøå]+.*)|[A-Za-zÆØÅæøå]+.*([0-9]+.*))/);
    return regex.test(string);
}
export function isFullNameValid(string) {
    if (string == null) {
        return false;
    }
    return string.split(/\W+/).length > 1;
}
